import { reviewsRoot } from "src/interfaces";

export const reviews = {
  "html_attributions": [],
  "result":
  {
    "address_components":
      [
        {
          "long_name": "6120",
          "short_name": "6120",
          "types":
            [
              "subpremise"
            ]
        },
        {
          "long_name": "5850",
          "short_name": "5850",
          "types":
            [
              "street_number"
            ]
        },
        {
          "long_name": "88 Avenue Northeast",
          "short_name": "88 Ave NE",
          "types":
            [
              "route"
            ]
        },
        {
          "long_name": "Northeast Calgary",
          "short_name": "Northeast Calgary",
          "types":
            [
              "neighborhood",
              "political"
            ]
        },
        {
          "long_name": "Calgary",
          "short_name": "Calgary",
          "types":
            [
              "locality",
              "political"
            ]
        },
        {
          "long_name": "Alberta",
          "short_name": "AB",
          "types":
            [
              "administrative_area_level_1",
              "political"
            ]
        },
        {
          "long_name": "Canada",
          "short_name": "CA",
          "types":
            [
              "country",
              "political"
            ]
        },
        {
          "long_name": "T3J 0Z7",
          "short_name": "T3J 0Z7",
          "types":
            [
              "postal_code"
            ]
        }
      ],
    "adr_address": "\u003cspan class=\"street-address\"\u003e5850 88 Ave NE #6120\u003c/span\u003e, \u003cspan class=\"locality\"\u003eCalgary\u003c/span\u003e, \u003cspan class=\"region\"\u003eAB\u003c/span\u003e \u003cspan class=\"postal-code\"\u003eT3J 0Z7\u003c/span\u003e, \u003cspan class=\"country-name\"\u003eCanada\u003c/span\u003e",
    "business_status": "OPERATIONAL",
    "current_opening_hours":
    {
      "open_now": false,
      "periods":
        [
          {
            "close":
            {
              "date": "2024-01-07",
              "day": 0,
              "time": "1700"
            },
            "open":
            {
              "date": "2024-01-07",
              "day": 0,
              "time": "1100"
            }
          },
          {
            "close":
            {
              "date": "2024-01-08",
              "day": 1,
              "time": "1930"
            },
            "open":
            {
              "date": "2024-01-08",
              "day": 1,
              "time": "0900"
            }
          },
          {
            "close":
            {
              "date": "2024-01-09",
              "day": 2,
              "time": "1930"
            },
            "open":
            {
              "date": "2024-01-09",
              "day": 2,
              "time": "0900"
            }
          },
          {
            "close":
            {
              "date": "2024-01-10",
              "day": 3,
              "time": "1930"
            },
            "open":
            {
              "date": "2024-01-10",
              "day": 3,
              "time": "0900"
            }
          },
          {
            "close":
            {
              "date": "2024-01-04",
              "day": 4,
              "time": "1930"
            },
            "open":
            {
              "date": "2024-01-04",
              "day": 4,
              "time": "0900"
            }
          },
          {
            "close":
            {
              "date": "2024-01-05",
              "day": 5,
              "time": "1930"
            },
            "open":
            {
              "date": "2024-01-05",
              "day": 5,
              "time": "0900"
            }
          },
          {
            "close":
            {
              "date": "2024-01-06",
              "day": 6,
              "time": "1700"
            },
            "open":
            {
              "date": "2024-01-06",
              "day": 6,
              "time": "1000"
            }
          }
        ],
      "weekday_text":
        [
          "Monday: 9:00 AM – 7:30 PM",
          "Tuesday: 9:00 AM – 7:30 PM",
          "Wednesday: 9:00 AM – 7:30 PM",
          "Thursday: 9:00 AM – 7:30 PM",
          "Friday: 9:00 AM – 7:30 PM",
          "Saturday: 10:00 AM – 5:00 PM",
          "Sunday: 11:00 AM – 5:00 PM"
        ]
    },
    "formatted_address": "5850 88 Ave NE #6120, Calgary, AB T3J 0Z7, Canada",
    "formatted_phone_number": "(403) 200-0222",
    "geometry":
    {
      "location":
      {
        "lat": 51.1328958,
        "lng": -113.9492979
      },
      "viewport":
      {
        "northeast":
        {
          "lat": 51.1343101302915,
          "lng": -113.9479484197085
        },
        "southwest":
        {
          "lat": 51.1316121697085,
          "lng": -113.9506463802915
        }
      }
    },
    "icon": "https://maps.gstatic.com/mapfiles/place_api/icons/v1/png_71/generic_business-71.png",
    "icon_background_color": "#7B9EB0",
    "icon_mask_base_uri": "https://maps.gstatic.com/mapfiles/place_api/icons/v2/generic_pinlet",
    "international_phone_number": "+1 403-200-0222",
    "name": "Millwoods Travels",
    "opening_hours":
    {
      "open_now": false,
      "periods":
        [
          {
            "close":
            {
              "day": 0,
              "time": "1700"
            },
            "open":
            {
              "day": 0,
              "time": "1100"
            }
          },
          {
            "close":
            {
              "day": 1,
              "time": "1930"
            },
            "open":
            {
              "day": 1,
              "time": "0900"
            }
          },
          {
            "close":
            {
              "day": 2,
              "time": "1930"
            },
            "open":
            {
              "day": 2,
              "time": "0900"
            }
          },
          {
            "close":
            {
              "day": 3,
              "time": "1930"
            },
            "open":
            {
              "day": 3,
              "time": "0900"
            }
          },
          {
            "close":
            {
              "day": 4,
              "time": "1930"
            },
            "open":
            {
              "day": 4,
              "time": "0900"
            }
          },
          {
            "close":
            {
              "day": 5,
              "time": "1930"
            },
            "open":
            {
              "day": 5,
              "time": "0900"
            }
          },
          {
            "close":
            {
              "day": 6,
              "time": "1700"
            },
            "open":
            {
              "day": 6,
              "time": "1000"
            }
          }
        ],
      "weekday_text":
        [
          "Monday: 9:00 AM – 7:30 PM",
          "Tuesday: 9:00 AM – 7:30 PM",
          "Wednesday: 9:00 AM – 7:30 PM",
          "Thursday: 9:00 AM – 7:30 PM",
          "Friday: 9:00 AM – 7:30 PM",
          "Saturday: 10:00 AM – 5:00 PM",
          "Sunday: 11:00 AM – 5:00 PM"
        ]
    },
    "photos":
      [
        {
          "height": 1440,
          "html_attributions":
            [
              "\u003ca href=\"https://maps.google.com/maps/contrib/106398004897890946716\"\u003eMillwoods Travels\u003c/a\u003e"
            ],
          "photo_reference": "AWU5eFgl7Yl1O-4dUKZ4aaHEcr7El0CJN2CskRwhZ4y7TkBchuSk2I_7hOTSofNqOgoPPisXhy05cVsz-sVF4f5wiBeCCbYiU2ILtCSZhew3Xzpp_aqXPsLHOgtEL53fJaILuCCYDkunV59OKm4Ya9hdM5o1eKOcndPEFBKRv-pNxtf5cwTa",
          "width": 1440
        }
      ],
    "place_id": "ChIJ_-oH7vFjcVMRqdMEWuwZoDw",
    "plus_code":
    {
      "compound_code": "43M2+57 Calgary, AB, Canada",
      "global_code": "953843M2+57"
    },
    "rating": 4.7,
    "reference": "ChIJ_-oH7vFjcVMRqdMEWuwZoDw",
    "reviews":
      [
        {
          "author_name": "sukhi sidhu",
          "author_url": "https://www.google.com/maps/contrib/110626184484506717844/reviews",
          "language": "en",
          "original_language": "en",
          "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocI1rzvPCiqoMhcmImAP1CoPxypD3abdBI-xP0tKjHlC=s128-c0x00000000-cc-rp-mo",
          "rating": 5,
          "relative_time_description": "a month ago",
          "text": "Unforgettable Cancun Trip -\nExceptional Service, Smooth Booking, Fantastic Experience, Excellent customer service in planning good vacation.\n\nI booked a vacation package to Cancun with Millwoods Travels and it was an incredible experience. Their outstanding customer service, even during the trip, and seamless booking made everything stress-free. The trip itself was fantastic. Highly recommended!",
          "time": 1700080521,
          "translated": false
        },
        {
          "author_name": "Gurwinder Dhanoa",
          "author_url": "https://www.google.com/maps/contrib/104350264060300569406/reviews",
          "language": "en",
          "original_language": "en",
          "profile_photo_url": "https://lh3.googleusercontent.com/a-/ALV-UjV4N0BUE0EuMZmEtNS6zEMR9AcGs3bBPpgl8T1gBQ9IQg=s128-c0x00000000-cc-rp-mo",
          "rating": 5,
          "relative_time_description": "a week ago",
          "text": "Recently booked a flight from Millwoods travels. Their service is excellent. Sakshi and Jasmita both were very helpful.\nThey went above and beyond to help us. Sakhsi spent more than an hour to find us the best flight and also was very reasonable price. She was very professional and polite.\n\nThis travel agency is my first choice now due to their superb customer service, i will be definitely recommending this place to friends and family.",
          "time": 1703395324,
          "translated": false
        },
        {
          "author_name": "saahil khanna",
          "author_url": "https://www.google.com/maps/contrib/117193535340320061947/reviews",
          "language": "en",
          "original_language": "en",
          "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocLdqjPxd9PsweEygOlujyvukauMxwldhDU3lC6d8quh=s128-c0x00000000-cc-rp-mo",
          "rating": 5,
          "relative_time_description": "4 weeks ago",
          "text": "Thank you so much \"Millwood Travels\" for being proactive, Maybe it seems like no big deal to you but this is amazing customer service and is greatly appreciated. They responds very promptly to requests and has helped to make wonderful travel plans for my family.\nHighly recommended.\nKindly do contact for best fares.",
          "time": 1701811824,
          "translated": false
        },
        {
          "author_name": "Avantika Singh",
          "author_url": "https://www.google.com/maps/contrib/117184179738664704517/reviews",
          "language": "en",
          "original_language": "en",
          "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocIf7QgjXYJhZdoGxLpsRlSqYimSsIED7UhvYjwbKNSG=s128-c0x00000000-cc-rp-mo",
          "rating": 5,
          "relative_time_description": "a month ago",
          "text": "Absolutely amazing service. Kritika helped me with not only booking my tickets but also got help me get a great price, ensure all the assistance and preferred meals. Highly recommended if you are looking for a hassle free experience to book airline tickets.\nAlso, Kritika is super polite and sweet, it feels like you’re talking to a friend.",
          "time": 1701120065,
          "translated": false
        },
        {
          "author_name": "Kirat Sandhu",
          "author_url": "https://www.google.com/maps/contrib/115636491815440191440/reviews",
          "language": "en",
          "original_language": "en",
          "profile_photo_url": "https://lh3.googleusercontent.com/a/ACg8ocK-pBWE63xFjFzwfSWiW1v5hTwttUYoJjyqLnCxehQR=s128-c0x00000000-cc-rp-mo",
          "rating": 5,
          "relative_time_description": "a month ago",
          "text": "Millwoods Travels Calgary, impressed me with their excellent ticketing and seamless planning. From securing optimal flights to well-coordinated accommodations, they delivered a top-notch experience. Clear communication and thoughtful suggestions made the trip memorable. Highly recommend Millwoods Travels for a hassle-free and enjoyable journey.\"",
          "time": 1700276599,
          "translated": false
        }
      ],
    "types":
      [
        "travel_agency",
        "point_of_interest",
        "establishment"
      ],
    "url": "https://maps.google.com/?cid=4368520141462623145",
    "user_ratings_total": 300,
    "utc_offset": -420,
    "vicinity": "5850 88 Avenue Northeast #6120, Calgary",
    "website": "https://millwoodstravels.ca/"
  },
  "status": "OK"
} as reviewsRoot;
