export const cards = [
  {
    title: "Award Winning Customer Service",
    description: `Our dedication to assisting and delighting travellers has earned 
    us prestigious awards, making us your trusted, award-winning travel partner.`,
  },
  {
    title: "Dedicated Travel Agents",
    description: `Our committed travel agents are at your service 24/7,
     from the moment you book your journey until you safely arrive at your destination.
      Your peace of mind is our priority!`,
  },
  {
    title: "8+ Years Of Expertise",
    description: `With 8+ years of experience, we are your dedicated travel partner for unforgettable journeys around the world.`,
  },
  {
    title: "Over 22 Travel Experts",
    description: `Our team members are dedicated to crafting your dream journeys,
     ensuring exceptional service and unforgettable travel experiences.`,
  },

  {
    title: "24x7 support",
    description: `We provide 24x7 support. You can contact us on our toll free number +1 (844) 245-7422`,
  },
];
