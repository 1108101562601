export const faqs = [
  {
    question: "How do I make a flight booking on Millwoods Travels?",
    answer: `Visit the Millwoods flight booking page, input your departure and arrival destinations,
     pick your air travel dates,
     select from our wide range of cheap flights based on your airfare preferences,
      and complete your booking by clicking on ‘Book Now’.`,
  },
  {
    question:
      "How do I contact customer support in case of issues or emergencies?",
    answer: `For immediate assistance or emergencies, 
    contact our customer support via phone at +1 (844) 245-7422 during operating hours.
     You can also email at calgary@millwoodstraves.ca with detailed information for a swift response.
      or reach out on our Social Media Platforms through direct messages.
     We are dedicated to resolving your concerns promptly and ensuring a seamless travel experience.`,
  },
  {
    question: "How do I receive my booking confirmation and travel itinerary?",
    answer: `Upon completing your flight booking, your confirmation and travel itinerary will be sent to the email address you provided during the booking process. Ensure to check your spam folder if you don't see the email in your inbox. For any concerns or if you are still waiting to receive your confirmation, feel free to contact our customer support, and we'll be happy to assist you.`,
  },
  {
    question: "Why Should I Book Flight Tickets With Millwoods Travels?",
    answer: `Choose Millwoods Travels for competitive prices, extensive flight options, and exclusive deals. Our user-friendly platform, dedicated customer support, and secure transactions ensure a seamless and satisfying booking experience.`,
  },
];
